import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import React, { useState ,useEffect} from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Topbar from "./Topbar";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from 'firebase/auth'
export default function Navigation() {
	let navigate = useNavigate();
	const [open, setopen] = useState(false);
	const authToken = sessionStorage.getItem('Auth Token')
	const [active, setactive] = useState(false)
useEffect(() => {
	if (authToken) {
		setactive(true)
	}
	if (!authToken) {
		setactive(false)
		navigate('/')
	}
}, [])

	function logout(){
		sessionStorage.clear()
		signOut(getAuth()).then(()=>navigate('/'))
		
	}
	return (
		<div>
			<Topbar />

			<div className="hamburger">
				<Container maxWidth="xl">
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div
							style={{
								paddingLeft: "12px",
								cursor: "pointer",
								paddingTop: "10px",
							}}
						>
							<img src="/Logo.svg" alt="" height={30} width={80} />
						</div>
						{authToken?						<div
							style={{ paddingRight: "24px", paddingTop: "6px" }}
							onClick={() => setopen(true)}
						>
							<MenuIcon fontSize="large" color="secondary" />
						</div>: <div></div> }

					</div>
				</Container>
			</div>

			<Drawer anchor="right" open={open}>
				<Box
					role="presentation"
					onClick={() => setopen(false)}
					// sx={{ width: "25vw", height: "100vh", overflowX: "hidden" }}
					sx={{ height: "100vh", overflowX: "hidden" }}
					className="bx"
					style={{ backgroundColor: "#000000" }}
				>
					<Topbar />
					<div className="hamburgera">
						<div
							style={{
								paddingLeft: "12px",
								cursor: "pointer",
								paddingTop: "10px",
							}}
						>
							<img src="/navigation.svg" alt="" height={45} width={120} />
						</div>
						<div
							style={{ paddingRight: "34px", paddingTop: "16px" }}
							onClick={() => setopen(false)}
						>
							<MenuOpenIcon fontSize="large" color="secondary" />
						</div>
					</div>
					<div className="ham">
						<ul>
							<li>
								<span className="hov" onClick={()=>navigate('/')}>Dashboard</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/products')}>Products</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/best-sellers')}>Best Sellers</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/monthly-special')}>Monthly Special</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/scents')}>Scents</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/topping')}>Toppings</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/designs')}>Designs</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/colors')}>Colors</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/coupon')}>Coupon</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/orders')}>Orders</span>
							</li>
							<li>
								<span className="hov" onClick={()=>navigate('/users')}>Users</span>
							</li>
							<li>
								<span className="hov" onClick={()=>logout()}>Logout</span>
							</li>
						</ul>
					</div>
				</Box>
			</Drawer>
		</div>
	);
}
