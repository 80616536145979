import { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
export default function Login() {
	const auth = getAuth();
	const [email, setemail] = useState("");
	const [password, setpassword] = useState("");

	function signin() {
		signInWithEmailAndPassword(auth, email, password)
			.then((response) => {
				if (response.user.uid == "mxZewoqTFOcbv8fhrSvEf33VMCU2") {
					sessionStorage.setItem(
						"Auth Token",
						response._tokenResponse.refreshToken
					)
				}else{
                    window.alert("Your not an admin user.")
                }
			})
			.finally(() => navigate("/"))
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
			});
	}
	let navigate = useNavigate();
	useEffect(() => {
		let authToken = sessionStorage.getItem("Auth Token");

		if (authToken) {
			navigate("/");
		}

		if (!authToken) {
			navigate("/login");
		}
	}, []);
	return (
		<div>
			<div className="signIn">
				<div className="row">
					<div className="col-lg-6">
						<img src="/sign-in-photo.png" className="w-100" alt="" />
					</div>
					<div className="col-lg-6">
						<div className="signInArea">
							<h1>Sign In</h1>
							<p>Good to have you back!</p>
							<div className="signInForm">
								<input
									type="email"
									className="form-control"
									placeholder="Email"
									onChange={(e) => setemail(e.target.value)}
								/>
								<input
									type="password"
									className="form-control"
									placeholder="Password"
									onChange={(e) => setpassword(e.target.value)}
								/>
							</div>
							<a className="btn signInButton" onClick={() => signin()}>
								Sign in
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
