import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
export default function Dashboard(){
	let navigate = useNavigate();
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
        if (!authToken) {
            navigate('/login')
        }
    }, [])
    return(
        <div>
            
        </div>
    )
}