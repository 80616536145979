import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import Card from "../components/ProductCard";
import { Link, useNavigate } from "react-router-dom";
export default function Products() {
	const [Data, setData] = useState([]);
	async function getData() {
		let DataArray = [];
		const q = query(collection(db, "Products"));
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			DataArray.push(doc.data());
		});
		setData(DataArray);
	}
	let navigate = useNavigate();
	useEffect(() => {
		getData();
		let authToken = sessionStorage.getItem("Auth Token");
		if (!authToken) {
			navigate("/login");
		}
	}, []);

	console.log(Data.length);

	return (
		<div className="top">
			<Container maxWidth="xl">
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Typography variant="h2" color="initial">
								Products
							</Typography>
						</div>
						<div style={{marginTop:'12px',marginRight:'40px'}}>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() => navigate("/create-product")}
							>
								Create Product
							</Button>
						</div>
					</div>
			
					<div class="tabContents">
						<div class="tabWrap">
							<div class="row">
								{Data.length == 0 ? (
									<div></div>
								) : (
									Data.map((e) => {
										return (
											<Card
												imageOu={e.main}
												imageOv={e.hover}
												name={e.name}
												pid={e.pid}
												price={e.category}
												state={e}
											/>
										);
									})
								)}
							</div>
						</div>
					</div>
			</Container>
		</div>
	);
}
