import { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
	return (
	  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
		<CircularProgress variant="determinate" {...props} />
		<Box
		  sx={{
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		  }}
		>
		  <Typography variant="caption" component="div" color="text.secondary">
			{`${Math.round(props.value)}%`}
		  </Typography>
		</Box>
	  </Box>
	);
  }
  
  CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired,
  };
  
export default function CreateProducts() {
	
	const [name, setname] = useState("");
	const [mTitle, setmTitle] = useState("");
	const [slug, setslug] = useState("");
	const [mKey, setmKey] = useState("");
	const [mDesc, setmDesc] = useState("");
	const [description, setdescription] = useState("");
	const [weight, setweight] = useState("");
	const [price, setprice] = useState("");
	const [category, setcategory] = useState("");
	const [lid, setlid] = useState("");
	const [jartype, setjartype] = useState("");
	const [jarsize, setjarsize] = useState("");
	const [main, setmain] = useState("");
	const [hover, sethover] = useState("");
	const [thumbnail, setthumbnail] = useState("");
	const [video, setvideo] = useState("");
	const [progresspercent, setProgresspercent] = useState(0);

	async function handleChange(e) {
		let i = name + "main" + (Math.random() + 1).toString(36).substring(7);
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Products/Main/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setmain(downloadURL);
				});
			}
		);
		console.log(main);
	}
	async function handleChanges(e) {
		let i = name + "hover" + (Math.random() + 1).toString(36).substring(7);
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Products/Hover/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					sethover(downloadURL);
				});
			}
		);
	}
	async function handleChangess(e) {
		let i = name + "Thumbnails" + (Math.random() + 1).toString(36).substring(7);
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Products/Thumbnails/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setthumbnail(downloadURL);
				});
			}
		);
		console.log(main);
	}

	async function submit() {
		const docRef = await addDoc(collection(db, "Products"), {
			name: name,
			description: description,
			weight: weight,
			price: price,
			category: category,
			lid: lid,
			jartype: jartype,
			jarsize: jarsize,
			main: main,
			hover: hover,
			thumbnail:thumbnail,
			metaTitle: mTitle,
			metaDescription: mDesc,
			metaKey: mKey,
			slug:slug,
			video: video,
			onWebsite: true,
			stock:0
		});
		const final = doc(db, "Products", docRef.id);

		await updateDoc(final, {
			pid: docRef.id,
		});
	}
	let navigate = useNavigate();
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
        if (!authToken) {
            navigate('/login')
        }
    }, [])
	return (
		<div className="top">
			<Container maxWidth="xl">
				<div style={{ paddingBottom: "30px" }}>
					<Typography variant="h3" color="initial">
						Create Product
					</Typography>
				</div>

				<Grid container spacing={0}>
					<Grid item xs={12} md={10} lg={8} xl={8}>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Name"
								variant="standard"
								fullWidth
								onChange={(e) => setname(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Description"
								variant="standard"
								fullWidth
								multiline
								maxRows={10}
								onChange={(e) => setdescription(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Title"
								variant="standard"
								fullWidth
								onChange={(e) => setmTitle(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Description"
								variant="standard"
								fullWidth
								onChange={(e) => setmDesc(e.target.value)}
								multiline
								maxRows={10}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Keyword"
								variant="standard"
								fullWidth
								onChange={(e) => setmKey(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Slug"
								variant="standard"
								fullWidth
								onChange={(e) => setslug(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Weight"
								variant="standard"
								fullWidth
								onChange={(e) => setweight(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "20px" }}>
							<TextField
								label="Product Price"
								variant="standard"
								fullWidth
								onChange={(e) => setprice(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the category type."
								fullWidth
								onChange={(e) => setcategory(e.target.value)}
							>
								<MenuItem value="Basics">Basics</MenuItem>
								<MenuItem value="Mini">Mini</MenuItem>
								<MenuItem value="Dessert">Dessert</MenuItem>
								<MenuItem value="Classic">Classic</MenuItem>
								<MenuItem value="Specials">Specials</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the lid type."
								fullWidth
								onChange={(e) => setlid(e.target.value)}
							>
								<MenuItem value="Silver">Silver</MenuItem>
								<MenuItem value="Gold">Gold</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the jar type."
								fullWidth
								onChange={(e) => setjartype(e.target.value)}
							>
								<MenuItem value="Transperant">Transperant</MenuItem>
								<MenuItem value="Frosted">Frosted</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the jar size."
								fullWidth
								onChange={(e) => setjarsize(e.target.value)}
							>
								<MenuItem value="Small">Small</MenuItem>
								<MenuItem value="Large">Large</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								Main Image
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								HoverImage
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChanges(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								3rd Image
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChangess(e)}
							/>
						</div>
						<Button
							variant="contained"
							color="primary"
							size="large"
							onClick={() => submit()}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
				{/* <Grid container spacing={0}>
					<Grid item xs={12} md={4} lg={3} xl={3} style={{textAlign:'center'}}>
						<label htmlFor="MainImage" style={{ cursor: "pointer" }}>
							<input accept="image/*" id="MainImage" type="file" hidden />
							<a>
								<span>
									<img src="main.png" alt="" />
								</span>
							</a>
						</label>
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
						<label htmlFor="HoverImage" style={{ cursor: "pointer" }}>
							<input accept="image/*" id="HoverImage" type="file" hidden />
							<a>
								<span>
									<img src="hover.png" alt="" />
								</span>
							</a>
						</label>
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
						<label htmlFor="Thumbnail" style={{ cursor: "pointer" }}>
							<input accept="image/*" id="Thumbnail" type="file" hidden />
							<a>
								<span>
									<img src="thumbnail.png" alt="" />
								</span>
							</a>
						</label>
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
						<label htmlFor="Video" style={{ cursor: "pointer" }}>
							<input accept="video/*" id="Video" type="file" hidden />
							<a>
								<span>
									<img src="video.png" alt="" />
								</span>
							</a>
						</label>
					</Grid>
				</Grid> */}
			</Container>
		</div>
	);
}
