import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
	collection,
	addDoc,
	updateDoc,
	doc,
	deleteDoc,
	getDoc,
} from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import Grid from "@mui/material/Grid";
export default function Designs() {
	let navigate = useNavigate();
	const [scent, setscent] = useState("");
	const [scoll, setscoll] = useState([]);
	const final = doc(db, "Common", "Designs");
	async function getData() {
		let arr = [];
		const q = doc(db, "Common", "Designs");
		const querySnapshot = await getDoc(q);
		arr = querySnapshot.data();
		setscoll(arr.collec);
		setscent("");
	}
	async function add() {
		let arr = [...scoll];
		arr.push(scent);
		updateDoc(final, {
			collec: arr,
		}).then(() => getData());
	}
	useEffect(() => {
		let authToken = sessionStorage.getItem("Auth Token");
		if (!authToken) {
			navigate("/login");
		}
		getData();
	}, []);
	return (
		<div className="top">
			<Container maxWidth="xl">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						<Typography variant="h2" color="initial">
							Designs Collection
						</Typography>
					</div>
				</div>
				<div>
					<Grid container spacing={0}>
						<Grid item xs={12} md={10} lg={8} xl={8}>
							<div style={{ paddingBottom: "16px" }}>
								<TextField
									label="Design Name"
									variant="standard"
									value={scent}
									fullWidth
									onChange={(e) => setscent(e.target.value)}
								/>
							</div>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() => add()}
							>
								Add Designs
							</Button>

							<div style={{ paddingTop: "66px" }}>
								<Typography variant="h4" color="initial">
									Scents Collection
								</Typography>
								{scoll.map((e, i) => {
									return (
										<div
											key={i}
											style={{ display: "flex", paddingTop: "30px" }}
										>
											<div style={{ paddingRight: "24px" }}>
												<Typography variant="h6" color="initial">
													{e}
												</Typography>
											</div>
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={() => {
													let arr = [...scoll];
													arr.splice(i, 1);
													updateDoc(final, {
														collec: arr,
													}).then(() => getData());
												}}
											>
												Delete
											</Button>
										</div>
									);
								})}
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
	);
}
