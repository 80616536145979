import { useState,useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { collection, addDoc, updateDoc, doc ,deleteDoc } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useLocation } from "react-router-dom";
import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';

function CircularProgressWithLabel(props) {
	return (
	  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
		<CircularProgress variant="determinate" {...props} />
		<Box
		  sx={{
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			position: 'absolute',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		  }}
		>
		  <Typography variant="caption" component="div" color="text.secondary">
			{`${Math.round(props.value)}%`}
		  </Typography>
		</Box>
	  </Box>
	);
  }
  
  CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired,
  };
  
export default function EditProducts() {
	const location = useLocation();
	const navigate = useNavigate();
	console.log(location.state);
	const [name, setname] = useState(location.state.name);
	const [mTitle, setmTitle] = useState(location.state.metaTitle);
	const [slug, setslug] = useState(location.state.slug || "");
	const [mKey, setmKey] = useState(location.state.metaKey);
	const [mDesc, setmDesc] = useState(location.state.metaDescription);
	const [description, setdescription] = useState(location.state.description);
	const [weight, setweight] = useState(location.state.weight);
	const [price, setprice] = useState(location.state.price);
	const [category, setcategory] = useState(location.state.category);
	const [lid, setlid] = useState(location.state.lid);
	const [jartype, setjartype] = useState(location.state.jartype);
	const [jarsize, setjarsize] = useState(location.state.jarsize);
	const [main, setmain] = useState(location.state.main);
	const [hover, sethover] = useState(location.state.hover);
	const [thumbnail, setthumbnail] = useState(location.state.thumbnail);
	
	const [video, setvideo] = useState(location.state.video || "");
	const [progresspercent, setProgresspercent] = useState(0);
	const [BestSeller, setBestSeller] = useState(location.state.bestSeller||false)
	const [onWebsite, setonWebsite] = useState(location.state.onWebsite)
	const [stock, setstock] = useState(location.state.stock)
	
	const final = doc(db, "Products", location.state.pid);
console.log(location.state.onWebsite)
console.log(onWebsite)

	const [progress, setProgress] = useState(0);
	async function handleVideo(e) {
		let i = name
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Videos/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setvideo(downloadURL);
				});
			}
		);
		console.log(main);
	}
	async function handleChange(e) {
		let i = name + "main" + (Math.random() + 1).toString(36).substring(7);
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Products/Main/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setmain(downloadURL);
				});
			}
		);
		console.log(main);
	}
	async function handleChanges(e) {
		let i = name + "hover" + (Math.random() + 1).toString(36).substring(7);
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Products/Hover/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					sethover(downloadURL);
				});
			}
		);
	}
	async function handleChangess(e) {
		let i = name + "Thumbnails" + (Math.random() + 1).toString(36).substring(7);
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/Products/Thumbnails/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setthumbnail(downloadURL);
				});
			}
		);
		console.log(main);
	}
	function submit() {
		updateDoc(final, {
			name: name,
			description: description,
			weight: weight,
			price: price,
			category: category,
			lid: lid,
			jartype: jartype,
			jarsize: jarsize,
			main: main,
			hover: hover,
			thumbnail: thumbnail,
			metaTitle: mTitle,
			metaDescription: mDesc,
			metaKey: mKey,
			slug: slug,
			bestSeller: BestSeller,
			onWebsite: onWebsite,
			video: video,
			stock:stock,
		}).then(() => navigate("/products"));
	}
	function deleteData(){
		deleteDoc(final)
		.then(() => navigate("/products"));
	}
    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')
        if (!authToken) {
            navigate('/login')
        }
    }, [])
	return (
		<div className="top">
			<Container maxWidth="xl">
				<div style={{ paddingBottom: "30px" }}>
					<Typography variant="h3" color="initial">
						Edit Product
					</Typography>
				</div>

				<Grid container spacing={0}>
					<Grid item xs={12} md={10} lg={8} xl={8}>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Name"
								variant="standard"
								value={name}
								fullWidth
								onChange={(e) => setname(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Description"
								variant="standard"
								fullWidth
								multiline
								maxRows={10}
								value={description}
								onChange={(e) => setdescription(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Title"
								variant="standard"
								value={mTitle}
								fullWidth
								onChange={(e) => setmTitle(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Description"
								variant="standard"
								fullWidth
								value={mDesc}
								onChange={(e) => setmDesc(e.target.value)}
								multiline
								maxRows={10}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Keyword"
								variant="standard"
								value={mKey}
								fullWidth
								onChange={(e) => setmKey(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Slug"
								value={slug}
								variant="standard"
								fullWidth
								onChange={(e) => setslug(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Weight"
								value={weight}
								variant="standard"
								fullWidth
								onChange={(e) => setweight(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "20px" }}>
							<TextField
								label="Product Price"
								variant="standard"
								value={price}
								fullWidth
								onChange={(e) => setprice(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Current Stock"
								value={stock}
								variant="standard"
								fullWidth
								onChange={(e) => setstock(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the category type."
								value={category}
								fullWidth
								onChange={(e) => setcategory(e.target.value)}
							>
								<MenuItem value="Basics">Basics</MenuItem>
								<MenuItem value="Frosted">Frosted</MenuItem>
								<MenuItem value="Minis">Minis</MenuItem>
								<MenuItem value="Dessert">Dessert</MenuItem>
								<MenuItem value="Classic">Classic</MenuItem>
								<MenuItem value="Specials">Specials</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								value={lid}
								helperText="Please select the lid type."
								fullWidth
								onChange={(e) => setlid(e.target.value)}
							>
								<MenuItem value="Silver">Silver</MenuItem>
								<MenuItem value="Gold">Gold</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the jar type."
								value={jartype}
								fullWidth
								onChange={(e) => setjartype(e.target.value)}
							>
								<MenuItem value="Transperant">Transperant</MenuItem>
								<MenuItem value="Frosted">Frosted</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the jar size."
								fullWidth
								value={jarsize}
								onChange={(e) => setjarsize(e.target.value)}
							>
								<MenuItem value="Small">Small</MenuItem>
								<MenuItem value="Large">Large</MenuItem>
							</TextField>
						</div>

						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								Main Image
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								HoverImage
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChanges(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								3rd Image
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChangess(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
							Show as BestSeller ?
							</Typography>
							<Switch  checked={BestSeller} onChange={(e)=>setBestSeller(e.target.checked)} />
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
							Show on website ?
							</Typography>
							<Switch  checked={onWebsite} onChange={(e)=>setonWebsite(e.target.checked)} />
						</div>
					</Grid>
					<Grid item xs={10} md={8} lg={5} xl={5}>
						<div style={{ display: "flex" }}>
							<div style={{ paddingRight: "14px" }}>
								<Button
									variant="contained"
									color="primary"
									size="large"
									onClick={() => submit()}
								>
									Submit
								</Button>
							</div>

							<div>
								<Button
									variant="contained"
									color="error"
									size="large"
									onClick={() => deleteData()}
								>
									Delete
								</Button>
							</div>
						</div>
					</Grid>
				</Grid>
				<Grid container spacing={4}>
					<Grid item xs={12} md={4} lg={3} xl={3} style={{textAlign:'center'}}>
					<div style={{marginRight:'12px',paddingTop:'20px'}}>
					<h4>Main Image</h4>
					<img src={main} alt=""  style={{height:'auto',width:'100%'}} />
					</div>
				
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
					<div style={{marginRight:'12px',paddingTop:'20px'}}>
					<h4>Hover Image</h4>
					<img src={hover} alt=""  style={{height:'auto',width:'100%'}} />
					</div>
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
					<div style={{marginRight:'12px',paddingTop:'20px'}}>
					<h4>3rd Image</h4>
					<img src={thumbnail} alt=""  style={{height:'auto',width:'100%'}} />
					</div>
					</Grid>

				</Grid>
			</Container>
		</div>
	);
}
