import "./App.css";
import { useState, useEffect } from "react";
import { analytics } from "./firebaseConfig";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, NavLink ,useNavigate } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Navigation from "./components/Navigation";
import CreateProducts from "./pages/CreateProduct";
import EditProducts from "./pages/EditProduct";
import "bootstrap/dist/css/bootstrap.min.css";
import Products from "./pages/Products";
import BestSeller from "./pages/BestSeller";
import ViewOrder from "./pages/ViewOrder";
import Users from "./pages/Users";
import MonthlySpecial from "./pages/MonthlySpecial";
import { getAuth, onAuthStateChanged } from '@firebase/auth'

import Login from "./pages/Login";
import Scents from "./pages/Scents";
import Designs from "./pages/Designs";
import Colors from "./pages/color";
import Coupon from "./pages/Coupon";
import Orders from "./pages/Orders";
import OrderView from "./pages/OrderView";
import Toppings from "./pages/Toppings";


function App() {
	const outerTheme = createTheme({
		palette: {
			common: { black: "#000", white: "#fff" },
			background: { paper: "#fff", default: "#fafafa" },
			primary: {
				light: "rgba(204, 204, 204, 1)",
				main: "rgba(19, 19, 19, 1)",
				dark: "rgba(101, 101, 101, 1)",
				contrastText: "rgba(250, 250, 249, 1)",
			},
			secondary: {
				light: "rgba(245, 245, 245, 1)",
				main: "rgba(255, 255, 255, 1)",
				dark: "rgba(236, 236, 236, 1)",
				contrastText: "rgba(19, 19, 19, 1)",
			},
			error: {
				light: "#e57373",
				main: "#f44336",
				dark: "#d32f2f",
				contrastText: "#fff",
			},
			text: {
				primary: "rgba(0, 0, 0, 0.87)",
				secondary: "rgba(0, 0, 0, 0.54)",
				disabled: "rgba(0, 0, 0, 0.38)",
				hint: "rgba(0, 0, 0, 0.38)",
			},
		},
	});

	return (
		<div className="App">
			<ThemeProvider theme={outerTheme}>
			
				<BrowserRouter>
				<Navigation />
					<Routes>
						<Route exact path="/" element={<Dashboard />} />
						<Route exact path="/login" element={<Login />} />
						<Route exact path="/create-product" element={<CreateProducts />} />
						<Route exact path="/edit-product" element={<EditProducts />} />
						<Route exact path="/products" element={<Products />} />
						<Route exact path="/best-sellers" element={<BestSeller />} />
						<Route exact path="/orders" element={<Orders />} />
						<Route exact path="/scents" element={<Scents />} />
						<Route exact path="/topping" element={<Toppings />} />
						<Route exact path="/designs" element={<Designs />} />
						<Route exact path="/colors" element={<Colors />} />
						<Route exact path="/coupon" element={<Coupon />} />
						<Route exact path="/users" element={<Users />} />
						<Route exact path="/monthly-special" element={<MonthlySpecial />} />
						<Route exact path="/orderView" element={<OrderView/>} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</div>
	);
}

export default App;
