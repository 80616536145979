import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
	collection,
	addDoc,
    setDoc,
	updateDoc,
	doc,
	deleteDoc,
	getDoc,
    getDocs
} from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import Grid from "@mui/material/Grid";
export default function Coupon() {
	let navigate = useNavigate();
	const [scent, setscent] = useState("");
    const [percent, setpercent] = useState(0);
	const [scoll, setscoll] = useState([]);
	// const final = doc(db, "Coupon", scent);
	async function getData() {
		let arr = [];
        const querySnapshot = await getDocs(collection(db, "Coupon"));
        querySnapshot.forEach((doc) => {
            arr.push({id:doc.id,value:doc.data()})
        });
		setscoll(arr);
		setscent("");
        setpercent(0)
	}
	async function add() {
		let arr = {[scent]:percent};
		await setDoc(doc(db, "Coupon", scent),arr);
        getData()
	}
	useEffect(() => {
		let authToken = sessionStorage.getItem("Auth Token");
		if (!authToken) {
			navigate("/login");
		}
		getData();
	}, []);
	return (
		<div className="top">
			<Container maxWidth="xl">
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div>
						<Typography variant="h2" color="initial">
							Coupon Collection
						</Typography>
					</div>
				</div>
				<div>
					<Grid container spacing={0}>
						<Grid item xs={12} md={10} lg={8} xl={8}>
							<div style={{ paddingBottom: "16px" }}>
								<TextField
									label="Coupon Name"
									variant="standard"
									value={scent}
									fullWidth
									onChange={(e) => setscent(e.target.value)}
								/>
							</div>
                            <div style={{ paddingBottom: "16px" }}>
								<TextField
									label="Coupon Percentege"
									variant="standard"
                                    type="number"
									value={percent}
									fullWidth
									onChange={(e) => setpercent(parseInt(e.target.value))}
								/>
							</div>
							<Button
								variant="contained"
								color="primary"
								size="large"
								onClick={() => add()}
							>
								Add Coupon
							</Button>

							<div style={{ paddingTop: "66px" }}>
								<Typography variant="h4" color="initial">
									Coupon Collection
								</Typography>
								{scoll.map((e, i) => {
									return (
										<div
											key={i}
											style={{ display: "flex", paddingTop: "30px" }}
										>
											<div style={{ paddingRight: "24px" }}>
												<Typography variant="h6" color="initial">
													{e.id}
												</Typography>
											</div>
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={() => {
												
                                                    deleteDoc(doc(db, "Coupon", e.id));
                                                    getData()
												}}
											>
												Delete
											</Button>
										</div>
									);
								})}
							</div>
						</Grid>
					</Grid>
				</div>
			</Container>
		</div>
	);
}
