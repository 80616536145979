import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
	collection,
	addDoc,
	updateDoc,
	doc,
	deleteDoc,
} from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { Container, Typography, Button } from "@mui/material";
export default function OrderView({ data, setViewD ,getData}) {
	console.log(data);
	const [finalStat, setfinalStat] = useState(data.status);
	const final = doc(db, "Order", data.fid);
	return (
		<div>
			<Container maxWidth="xl">
				<div style={{ paddingTop: "24px" }}>
					<h2>User Details</h2>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={finalStat}
						label="Status"
						onChange={(e) => {
							setfinalStat(e.target.value);
							updateDoc(final, {
								status: e.target.value,
							}).then(() => setViewD(false));
						}}
					>
						<MenuItem value={"Under Verification"}>Under Verification</MenuItem>
						<MenuItem value={"Paid and Pending"}>Paid and Pending</MenuItem>
						<MenuItem value={"Unpaid and Pending"}>Unpaid and Pending</MenuItem>
						<MenuItem value={"Cancelled"}>Cancelled</MenuItem>
						<MenuItem value={"Unpaid and on Delivery"}>
							Unpaid and on Delivery
						</MenuItem>
						<MenuItem value={"Paid and on Delivery"}>
							Paid and on Delivery
						</MenuItem>
						<MenuItem value={"Delivered"}>Delivered</MenuItem>
					</Select>
					<div style={{paddingTop:'20px'}}>
					<Button variant="contained" color="primary" size="large" onClick={async()=>{
						await deleteDoc(doc(db, "Order", data.fid))
						setViewD(false)
						getData()
					}}>
						Delete
					</Button>
					</div>
					<div style={{ paddingTop: "20px" }}>
						{Object.entries(data).map(([key, value], index) =>
							typeof value == "object" ? (
								""
							) : (
								<p
									key={index}
									style={{ display: "flex", justifyContent: "space-between" }}
								>
									<span>{key}: </span>
									<span>{value}</span>
								</p>
							)
						)}
					</div>
					<h2>Order Details</h2>
					<div>
						{data?.items?.map((r) => {
							return r.type == "custom" ? (
								<a className="side-cart-item">
									<div className="single-cart-item-img">
										<img src="/custom.png" alt="" />
									</div>
									<div className="single-cart-item-info">
										<h1>{r.name}</h1>
										<p>{r.category}</p>
									</div>
									<div className="single-cart-item-info">
									<p>Secret Message : {r.secretMessage}</p>
									<p>Note : {r.noteData}</p>
										<p>Colors : {r.color.toString()}</p>
										<p>Scent : {r.scent.toString()}</p>
										<p>Toppings: {r?.topping?.toString()}</p>
										<p>Design : {r.design}</p>
										<p>Jar : {r.jarType}</p>
										<p>{r.category}</p>
									</div>
									<div className="single-cart-item-info">
										<p>Vibe : {r.vibe}</p>
									</div>
									<p className="price">BDT {r.price}</p>
								</a>
							) : (
								<a className="side-cart-item">
									<div className="single-cart-item-img">
										<img src={r.main} alt="" />
									</div>
									<div className="single-cart-item-info">
										<h1>{r.name}</h1>
										<p>{r.category}</p>
									</div>
									<p className="price">BDT {r.price}</p>
								</a>
							);
						})}
					</div>
				</div>
			</Container>
		</div>
	);
}
