import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { db, storage } from "../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import OrderView from "./OrderView";
import { DataArray } from "@mui/icons-material";
const columns = [
    { field: 'ids', headerName: '#ID', width: 140 },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'phoneNumberC',
      headerName: 'Phone',
      width: 150,
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      width: 150,

    },
    {
      field: 'payment',
      headerName: 'Payment',
      width: 150,
    },
    {
      field: 'discount',
      headerName: 'Discount',
      width: 150,
    },
    {
      field: 'coupon',
      headerName: 'Coupon',
      width: 150,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
  ];
  
  

export default function Orders(){
    let navigate = useNavigate();
    const [rows, setrows] = useState([])
    const [ViewD, setViewD] = useState(false)
    const [MainD, setMainD] = useState({})
    async function getData() {
      let DataArray = [];
      let x=0
      const q = query(collection(db, "Order"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let x = doc.data()
        let s =doc.id
        const fireBaseTime = new Date(
          x.orderDate.seconds * 1000 + x.orderDate.nanoseconds / 1000000,
        );
        DataArray.push({...x,fid:s,date:fireBaseTime});

      });
      setrows(DataArray);
    }

    const handleRowClick = (
      params, // GridRowParams
      event, // MuiEvent<React.MouseEvent<HTMLElement>>
      details, // GridCallbackDetails
    ) => {
      // navigate('/orderView')
      setViewD(true)
      setMainD(params.row)
    };
    console.log(rows)
    useEffect(() => {
      getData()
    }, [])
    useEffect(() => {
      getData()
    }, [ViewD])
    
    useEffect(() => {
     
        let authToken = sessionStorage.getItem('Auth Token')
        if (!authToken) {
            navigate('/login')
        }
    }, [])
    return(
      ViewD? <OrderView data={MainD} setViewD={setViewD} getData={getData} />:
        <div>
     <Box sx={{ height: 800, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
  //       filterMode='client'
  //       filterModel={{
  //   items: ["Pending"]
  // }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 60,
            },
          },
        }}
        onRowClick={handleRowClick}
        pageSizeOptions={[60]}
        disableRowSelectionOnClick
      />
    </Box>
        </div>
    )
}