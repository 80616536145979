import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Container, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
	collection,
	addDoc,
	updateDoc,
	doc,
	deleteDoc,
	query,
	where,
	getDocs,
	setDoc,
	getDoc,
} from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { useLocation } from "react-router-dom";
import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";

function CircularProgressWithLabel(props) {
	return (
		<Box sx={{ position: "relative", display: "inline-flex" }}>
			<CircularProgress variant="determinate" {...props} />
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: "absolute",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Typography variant="caption" component="div" color="text.secondary">
					{`${Math.round(props.value)}%`}
				</Typography>
			</Box>
		</Box>
	);
}

CircularProgressWithLabel.propTypes = {
	/**
	 * The value of the progress indicator for the determinate variant.
	 * Value between 0 and 100.
	 * @default 0
	 */
	value: PropTypes.number.isRequired,
};

export default function MonthlySpecial() {
	async function getData() {
		let DataArray = [];
		const q = doc(db, "Common", "MonthlySpecial");
		const querySnapshot = await getDoc(q);
		DataArray = querySnapshot.data();

		setname(DataArray.name);
		setmTitle(DataArray.metaTitle);
		setmKey(DataArray.metaKey);
		setmDesc(DataArray.metaDescription);
		setweight(DataArray.weight);
		setprice(DataArray.price);
		setcategory(DataArray.category);
		setlid(DataArray.lid);
		setjartype(DataArray.jartype);
		setjarsize(DataArray.jarsize);
		setmain(DataArray.main);
		sethover(DataArray.hover);
		setthumbnail(DataArray.thumbnail);
		setvideos(DataArray.hvideo);
	}

	const navigate = useNavigate();
	const [Data, setData] = useState([]);
	const [name, setname] = useState("");
	const [mTitle, setmTitle] = useState("");
	const [mKey, setmKey] = useState("");
	const [mDesc, setmDesc] = useState("");
	const [description, setdescription] = useState("");
	const [weight, setweight] = useState("");
	const [price, setprice] = useState("");
	const [category, setcategory] = useState("");
	const [lid, setlid] = useState("");
	const [jartype, setjartype] = useState("");
	const [jarsize, setjarsize] = useState("");
	const [main, setmain] = useState("");
	const [hover, sethover] = useState("");
	const [thumbnail, setthumbnail] = useState("");
	const [video, setvideo] = useState("");
	const [videos, setvideos] = useState("");
	const [progresspercent, setProgresspercent] = useState(0);
	const [BestSeller, setBestSeller] = useState("");

	const [progresss, setProgresss] = useState(0);
	async function handleVideos(e) {
		let i = "Videos";
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `MonthlySpecial/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresss(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setvideos(downloadURL);
				});
			}
		);
		console.log(main);
	}


	async function handleChange(e) {
		let i = "main";
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `MonthlySpecial/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setmain(downloadURL);
				});
			}
		);
		console.log(main);
	}
	async function handleChanges(e) {
		let i = "hover";
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/MonthlySpecial/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					sethover(downloadURL);
				});
			}
		);
	}
	async function handleChangess(e) {
		let i = "thumbnail";
		const file = e.target.files[0];

		if (!file) return;

		const storageRef = ref(storage, `/MonthlySpecial/${i}`);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					setthumbnail(downloadURL);
				});
			}
		);
		console.log(main);
	}
	function submit() {
		setDoc(doc(db, "Common", "MonthlySpecial"), {
			name: name,
			description: description,
			weight: weight,
			price: price,
			category: category,
			lid: lid,
			jartype: jartype,
			jarsize: jarsize,
			main: main,
			hover: hover,
			thumbnail: thumbnail,
			metaTitle: mTitle,
			metaDescription: mDesc,
			metaKey: mKey,
			hvideo: videos,
		}).then(() => navigate("/"));
	}

	useEffect(() => {
		getData();
		let authToken = sessionStorage.getItem("Auth Token");
		if (!authToken) {
			navigate("/login");
		}
	}, []);
	return (
		<div className="top">
			<Container maxWidth="xl">
				<div style={{ paddingBottom: "30px" }}>
					<Typography variant="h3" color="initial">
						Monthly Special
					</Typography>
				</div>
				<Grid container spacing={0}>
					<Grid item xs={12} md={10} lg={8} xl={8}>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								Homepage Video for MonthlySpecial
							</Typography>
							<input
								accept="video/*"
								type="file"
								onChange={(e) => handleVideos(e)}
							/>
							<CircularProgressWithLabel value={progresss} />
						</div>
					</Grid>
				</Grid>
				<br />
				<br />
				<div style={{ paddingBottom: "30px" }}>
					<Typography variant="h4" color="initial">
						Product Info
					</Typography>
				</div>
				<Grid container spacing={0}>
					<Grid item xs={12} md={10} lg={8} xl={8}>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Name"
								variant="standard"
								value={name}
								fullWidth
								onChange={(e) => setname(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Description"
								variant="standard"
								fullWidth
								multiline
								maxRows={10}
								value={description}
								onChange={(e) => setdescription(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Title"
								variant="standard"
								value={mTitle}
								fullWidth
								onChange={(e) => setmTitle(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Description"
								variant="standard"
								fullWidth
								value={mDesc}
								onChange={(e) => setmDesc(e.target.value)}
								multiline
								maxRows={10}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Meta Keyword"
								variant="standard"
								value={mKey}
								fullWidth
								onChange={(e) => setmKey(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								label="Product Weight"
								value={weight}
								variant="standard"
								fullWidth
								onChange={(e) => setweight(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "20px" }}>
							<TextField
								label="Product Price"
								variant="standard"
								value={price}
								fullWidth
								onChange={(e) => setprice(e.target.value)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the category type."
								value={category}
								fullWidth
								onChange={(e) => setcategory(e.target.value)}
							>
								<MenuItem value="Basics">Basics</MenuItem>
								<MenuItem value="Frosted">Frosted</MenuItem>
								<MenuItem value="Minis">Minis</MenuItem>
								<MenuItem value="Dessert">Dessert</MenuItem>
								<MenuItem value="Specials">Specials</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								value={lid}
								helperText="Please select the lid type."
								fullWidth
								onChange={(e) => setlid(e.target.value)}
							>
								<MenuItem value="Silver">Silver</MenuItem>
								<MenuItem value="Gold">Gold</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the jar type."
								value={jartype}
								fullWidth
								onChange={(e) => setjartype(e.target.value)}
							>
								<MenuItem value="Transperant">Transperant</MenuItem>
								<MenuItem value="Frosted">Frosted</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<TextField
								select
								label="Select"
								helperText="Please select the jar size."
								fullWidth
								value={jarsize}
								onChange={(e) => setjarsize(e.target.value)}
							>
								<MenuItem value="Small">Small</MenuItem>
								<MenuItem value="Large">Large</MenuItem>
							</TextField>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								Main Image
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChange(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								HoverImage
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChanges(e)}
							/>
						</div>
						<div style={{ paddingBottom: "16px" }}>
							<Typography variant="h5" color="initial">
								3rd Image
							</Typography>
							<input
								accept="image/*"
								type="file"
								onChange={(e) => handleChangess(e)}
							/>
						</div>
					</Grid>
					<Grid item xs={10} md={8} lg={5} xl={5}>
						<div style={{ display: "flex" }}>
							<div style={{ paddingRight: "14px" }}>
								<Button
									variant="contained"
									color="primary"
									size="large"
									onClick={() => submit()}
								>
									Save Changes
								</Button>
							</div>

							<div></div>
						</div>
					</Grid>

				</Grid>
				<Grid container spacing={4}>
					<Grid item xs={12} md={4} lg={3} xl={3} style={{textAlign:'center'}}>
					<div style={{marginRight:'12px',paddingTop:'20px'}}>
					<h4>Main Image</h4>
					<img src={main} alt=""  style={{height:'auto',width:'100%'}} />
					</div>
				
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
					<div style={{marginRight:'12px',paddingTop:'20px'}}>
					<h4>Hover Image</h4>
					<img src={hover} alt=""  style={{height:'auto',width:'100%'}} />
					</div>
					</Grid>
					<Grid item xs={12} md={4} lg={3} xl={3}  style={{textAlign:'center'}}>
					<div style={{marginRight:'12px',paddingTop:'20px'}}>
					<h4>3rd Image</h4>
					<img src={thumbnail} alt=""  style={{height:'auto',width:'100%'}} />
					</div>
					</Grid>

				</Grid>
			</Container>
		</div>
	);
}
