
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBaSEShTjyY9vDGTJ8d9rvODkhm41Tfsi4",
  authDomain: "nuzzld-website.firebaseapp.com",
  projectId: "nuzzld-website",
  storageBucket: "nuzzld-website.appspot.com",
  messagingSenderId: "145308137105",
  appId: "1:145308137105:web:6f138a7ed27bc52d6bb130",
  measurementId: "G-C1ZTDKZXPE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)
const storage = getStorage(app);
export {db,analytics,app,storage}