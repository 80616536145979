import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
export default function Card({ imageOu, price, pid, name, imageOv,state }) {
	const navigate = useNavigate();
	return (
		<div className="col-lg-3 col-md-4 col-sm-6 col-6 ">
			<div
				className="singleBestSellingProduct"
				onClick={() => {
					navigate("/edit-product", { state: state });
				}}
			>
				<div className="singleBestSellingProductImg">
					<img src={imageOu} alt="" />
					<img src={imageOv} className="secondImg" alt="" />
				</div>
				<div className="singleBestSellingProductInfo">
					<Typography variant="h4" color="initial">
						{name}
					</Typography>
					<Typography variant="h5" color="initial">
						{price}
					</Typography>
				</div>
			</div>
		</div>
	);
}
