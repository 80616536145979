import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, storage } from "../firebaseConfig";
import Card from "../components/ProductCard";
import Grid from "@mui/material/Grid";
import { Container, Typography, Button } from "@mui/material";
const q = query(collection(db, "Products"), where("bestSeller", "==", true));
export default function BestSeller() {
	let navigate = useNavigate();
	const [Data, setData] = useState([]);
	async function getData() {
		let DataArray = [];
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			DataArray.push(doc.data());
		});
		setData(DataArray);
	}
	useEffect(() => {
		getData();
		let authToken = sessionStorage.getItem("Auth Token");
		if (!authToken) {
			navigate("/login");
		}
	}, []);
	return (
<div className="top">
			<Container maxWidth="xl">
		
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<Typography variant="h2" color="initial">
								Best Sellers
							</Typography>
						</div>
					</div>
			
					<div class="tabContents">
						<div class="tabWrap">
							<div class="row">
								{Data.length == 0 ? (
									<div></div>
								) : (
									Data.map((e) => {
										return (
											<Card
												imageOu={e.main}
												imageOv={e.hover}
												name={e.name}
												pid={e.pid}
												price={e.category}
												state={e}
											/>
										);
									})
								)}
							</div>
						</div>
					</div>
			</Container>
		</div>
	);
}
